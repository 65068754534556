import React, {useEffect, useRef, useState} from "react";
import { ReactTyped } from "react-typed";
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface TypeOutProps {
  children: string;
  typeSpeed: number;
}

const TypeOut: React.FC<TypeOutProps> = ({ children, typeSpeed }) => {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    gsap.to(ref.current, {
      scrollTrigger: {
        trigger: ref.current,
        start: 'top center', // Adjust the trigger point as needed
        onEnter: () => setIsInView(true),
        onEnterBack: () => setIsInView(true), // Optional: Trigger animation when scrolling back up
        once: true, // Ensure the animation runs only once
      },
    });

    return () => {
      // Clean up ScrollTrigger to prevent memory leaks
      ScrollTrigger.getAll().forEach((instance) => instance.kill());
    };
  }, []);

  return (
    <div ref={ref}>
      {isInView && (
        <ReactTyped
          strings={[children]}
          typeSpeed={typeSpeed}
          showCursor={false}
          startDelay={0}
        />
      )}
    </div>
  );
};

export default TypeOut;