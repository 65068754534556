import './my_projects.css';
import "../Theme.css";
import React, { useEffect, useState } from 'react';
import { Box, Grid, List, Typography } from '@mui/material';
import { Project } from '../../../../api/data_types';
import { fetchProjects } from '../../backend/projects';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import GlowDiv from '../../components/GlowDiv/GlowDiv';

const My_projects = () => {
    const [projectsPerRow, setProjectsPerRow] = useState<number>(4);
    const [glowSizeMultiplier, setGlowSizeMultiplier] = useState<number>(0.3);
    const [projects, setProjects] = useState<Project[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadProjects = async () => {
            try {
                const data = await fetchProjects();
                if (data.projects && data.projectsPerRow) {
                    setProjects(data.projects);
                    setProjectsPerRow(data.projectsPerRow); // Update state based on fetched data
                    setGlowSizeMultiplier(data.glowSizeMultiplier);
                    document.documentElement.style.setProperty('--projects-per-row', data.projectsPerRow.toString());
                    console.log("glowSizeMultiplier (fetchedProjects): ", data.glowSizeMultiplier);
                } else {
                    setError('No project data available');
                }
            } catch (error) {
                setError('Failed to fetch projects');
                console.error(error);
            }
        };
        loadProjects();
    }, []);

    useEffect(() => {
        console.log("Updated glowSizeMultiplier: ", glowSizeMultiplier);
    }, [glowSizeMultiplier]); // This useEffect will run whenever glowSizeMultiplier changes    

    const groupProjects = (projects: Project[]): Project[][] => {
        const groupedProjects: Project[][] = [];
        for (let i = 0; i < projects.length; i += projectsPerRow) {
            // Use slice to get up to the next four items and push that as a new group
            groupedProjects.push(projects.slice(i, i + projectsPerRow));
        }
        return groupedProjects;
    }

    return (
        <Box className='slide' id='my_projects_slide'>
            <Grid className='slide_container' id='my_projects_slide_container' justifyContent={'center'}>
                <Grid item className='slide_content' id='slide-title'>
                    My Projects
                </Grid>
                <GlowDiv glowSizeMultiplier={glowSizeMultiplier}>
                    {error ? (
                        <Typography variant="h6" color="error" textAlign="center">
                            {error} {/* Displaying the error message */}
                        </Typography>
                    ) : (
                        groupProjects(projects).map((group, index) => (
                            <List key={index} className='component_container' disablePadding={true}>
                                {group.map(project => (
                                    <ProjectCard key={project.id} id={project.id} projectTitle={project.projectTitle} imageUrl={project.imageUrl} projectDescription={project.projectDescription} projectGitHubLink={project.projectGitHubLink} projectsPerRow={projectsPerRow} />
                                ))}
                            </List>
                        ))
                    )}
                </GlowDiv>
            </Grid>
        </Box>
    );
};

export default My_projects;
