import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import './ThemeDashboard.css';

const ThemeDashboard = () => {
    const [colors, setColors] = useState({
        color1: '',
        color2: '',
        color3: '',
        color4: ''
    });

    const themesBaseUrl = process.env.REACT_APP_THEMES_BASE_URL; // Base URL for the API server

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setColors({ ...colors, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch(`${themesBaseUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(colors),
            });

            if (response.ok) {
                alert('Theme updated successfully');
                setColors({ color1: '', color2: '', color3: '', color4: '' });
            } else {
                alert('Failed to update theme');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error updating theme');
        }
    };

    return (
        <Box className="theme-dashboard">
            <Typography variant="h4" gutterBottom>
                Update Theme Colors
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Color 1"
                    name="color1"
                    value={colors.color1}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Color 2"
                    name="color2"
                    value={colors.color2}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Color 3"
                    name="color3"
                    value={colors.color3}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Color 4"
                    name="color4"
                    value={colors.color4}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary">
                    Update Theme
                </Button>
            </form>
        </Box>
    );
};

export default ThemeDashboard;