import { useState } from 'react';
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import './ExperienceDashboard.css';

const ExperienceDashboard = () => {
    const [newElement, setNewElement] = useState({
        type: '',
        date: '',
        title: '',
        subtitle: '',
        description: ''
    });
    const [deleteElementId, setDeleteElementId] = useState('');

    const experiencesBaseUrl = process.env.REACT_APP_TIMELINE_ELEMENTS_BASE_URL; // Base URL for the API server

    const handleAddElementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewElement({ ...newElement, [name]: value });
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setNewElement({ ...newElement, [name as string]: value as string });
    };

    const handleAddElementSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { type, date, title, subtitle, description } = newElement;
        if (!type || !date || !title || !subtitle || !description) {
            alert('Please provide all fields for the timeline element.');
            return;
        }

        try {
            const response = await fetch(`${experiencesBaseUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newElement),
                credentials: 'include',
            });

            if (response.ok) {
                alert('Timeline element added successfully');
                setNewElement({ type: '', date: '', title: '', subtitle: '', description: '' });
            } else {
                alert('Failed to add timeline element');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error adding timeline element');
        }
    };

    const handleDeleteElementSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!deleteElementId) {
            alert('Please provide the ID of the timeline element to delete.');
            return;
        }

        try {
            const response = await fetch(`${experiencesBaseUrl}/${deleteElementId}`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (response.ok) {
                alert('Timeline element deleted successfully');
                setDeleteElementId('');
            } else {
                alert('Failed to delete timeline element');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error deleting timeline element');
        }
    };

    return (
        <Box className="experience-dashboard">
            <form className='form-class' onSubmit={handleAddElementSubmit}>
                <h2>Add a New Timeline Element</h2>
                <FormControl fullWidth required>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                        labelId="type-label"
                        name="type"
                        value={newElement.type}
                        onChange={handleSelectChange}
                    >
                        <MenuItem value="work">Work</MenuItem>
                        <MenuItem value="education">Education</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Date"
                    name="date"
                    value={newElement.date}
                    onChange={handleAddElementChange}
                    fullWidth
                    required
                />
                <TextField
                    label="Title"
                    name="title"
                    value={newElement.title}
                    onChange={handleAddElementChange}
                    fullWidth
                    required
                />
                <TextField
                    label="Subtitle"
                    name="subtitle"
                    value={newElement.subtitle}
                    onChange={handleAddElementChange}
                    fullWidth
                    required
                />
                <TextField
                    label="Description"
                    name="description"
                    value={newElement.description}
                    onChange={handleAddElementChange}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="primary">
                    Add Timeline Element
                </Button>
            </form>

            <form onSubmit={handleDeleteElementSubmit}>
                <h2>Delete a Timeline Element</h2>
                <TextField
                    label="Element ID"
                    value={deleteElementId}
                    onChange={(e) => setDeleteElementId(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="secondary">
                    Delete Timeline Element
                </Button>
            </form>
        </Box>
    );
};

export default ExperienceDashboard;