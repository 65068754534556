import axios from "axios";
import { TimelineItem } from "../../../api/data_types";

const BASE_URL = process.env.REACT_APP_TIMELINE_ELEMENTS_BASE_URL;

if (!BASE_URL) {
    throw new Error('REACT_APP_TIMELINE_ELEMENTS_BASE_URL is not defined in the environment variables');
}

export const fetchTimelineElements = async (): Promise<TimelineItem[]> => {
    try {
        console.log('Fetching timeline elements');
        const response = await axios.get<{ elements: TimelineItem[] }>(BASE_URL);
        console.log('Timeline elements fetched', response.data);

        // Sort elements by ID in descending order (most recent first)
        const sortedElements = response.data.elements.sort((a, b) => parseInt(b.id) - parseInt(a.id));

        return sortedElements;
    } catch (error: any) {
        console.error('Failed to fetch timeline elements:', error.response ? error.response.data : error.message);
        throw new Error('Failed to fetch timeline elements');
    }
};
