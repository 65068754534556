import React from 'react';
import { Modal, Box } from '@mui/material';

interface ImageModalProps {
    open: boolean;
    onClose: () => void;
    imageUrl: string;
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ImageModal: React.FC<ImageModalProps> = ({ open, onClose, imageUrl }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <img src={imageUrl} alt="Large View" style={{ width: '100%', height: 'auto' }} />
            </Box>
        </Modal>
    );
};

export default ImageModal;