import React from 'react';
import "../Theme.css";
import "./Intro_slide.css";
import TypeOut from '../../components/TypeOut/TypeOut';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

const Intro_slide = () => {
    return (
        <Box className='slide' id='intro_slide'>
            <Grid className='slide_container' id='intro_slide_container'>
                <Grid item className='slide_content' id='intro-phrase'>
                    Hello, my name is
                </Grid>
                <Grid item className='slide_content' id='name'>
                    <TypeOut typeSpeed={55}>Saad Sheikh</TypeOut>
                </Grid>
                <Grid item className='slide_content' id='introduction'>
                    I’m a 4th year undergraduate student at Carleton University in the Computer Science program. I enjoy taking on new programming challenges to broaden my scope of knowledge. I hope to build a career through acquired knowledge and aspire to become more adept as a software developer.
                </Grid>
            </Grid>
        </Box>
    );
};

export default Intro_slide;