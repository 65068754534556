import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import './LoginPage.css';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const loginBaseUrl = process.env.REACT_APP_LOGIN_BASE_URL; // Base URL for the API server

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${loginBaseUrl}`, { username, password });
            if (response.status === 200) {
                navigate('/dashboard');
            }
        } catch (error) {
            setError('Invalid credentials');
        }
    };

    return (
        <Box className="login-page">
            <h1>Login</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button onClick={handleLogin}>Login</Button>
        </Box>
    );
};

export default LoginPage;
