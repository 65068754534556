import React from 'react';
import "../Theme.css";
import "./socials.css";
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import { GitHub, Google, LinkedIn } from '@mui/icons-material';

const Socials_slide = () => {
    const email = process.env.REACT_APP_EMAIL;
    const github = process.env.REACT_APP_GITHUB;
    const linkedin = process.env.REACT_APP_LINKEDIN;

    return (
        <Box className='slide' id='socials_slide'>
            <Grid className='slide_container' id='socials_slide_container'>
                {email && (
                    <Button href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                        <Google sx={{ scale: '2.0', color: 'var(--color-palette-4)' }} />
                    </Button>
                )}
                {github && (
                    <Button href={github} target="_blank" rel="noopener noreferrer">
                        <GitHub sx={{ scale: '2.0', color: 'var(--color-palette-4)' }} />
                    </Button>
                )}
                {linkedin && (
                    <Button href={linkedin} target="_blank" rel="noopener noreferrer">
                        <LinkedIn sx={{ scale: '2.0', color: 'var(--color-palette-4)' }} />
                    </Button>
                )}
            </Grid>
        </Box>
    );
};

export default Socials_slide;
