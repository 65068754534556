import './ProjectCard.css';
import React, { useState } from 'react';
import { Code } from '@mui/icons-material';
import ImageModal from '../ImageModal/ImageModal';

interface ProjectCardProps {
    id: string,
    imageUrl: string,
    projectTitle: string,
    projectDescription: string,
    projectGitHubLink: string,
    projectsPerRow: number
}

const ProjectCard: React.FC<ProjectCardProps> = ({ id, imageUrl, projectTitle, projectDescription, projectGitHubLink, projectsPerRow }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleImageClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <div className="card-container project-card-background">
            <div className='image-container' onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                <img src={imageUrl} alt="Card Visual" />
            </div>
            <div className='card-title-container' id={`${id}-container`}>
                <div className="card-title" id={`${id}-title`}>{projectTitle}</div>
            </div>
            <div className='card-info-container' id={`${id}-container`}>
                <div className="card-info" id={`${id}-title`}>{projectDescription}</div>
            </div>
            <div className='GitHub-link-container'>
                <a href={projectGitHubLink} target="_blank" rel="noopener noreferrer">
                    <Code className='code-icon' />
                </a>
            </div>
            <ImageModal open={modalOpen} onClose={handleClose} imageUrl={imageUrl} />
        </div>
    );
}

export default ProjectCard;
