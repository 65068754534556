import axios from 'axios';
import { Project } from '../../../api/data_types';

const BASE_URL = process.env.REACT_APP_PROJECTS_BASE_URL as string;

if (!BASE_URL) {
    throw new Error('REACT_APP_PROJECTS_BASE_URL is not defined in the environment variables');
}

export const fetchProjects = async (): Promise<{ projects: Project[], projectsPerRow: number, glowSizeMultiplier: number }> => {
    try {
        const response = await axios.get<{ projects: Project[], projectsPerRow: number, glowSizeMultiplier: number }>(BASE_URL);
        console.log("Projects fetched from server:", response.data);
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch projects:', error.message);
        throw new Error('Failed to fetch projects');
    }
};

export const addProject = async (newProject: Project): Promise<Project> => {
    try {
        const response = await axios.post<Project>(BASE_URL, newProject);
        return response.data;
    } catch (error) {
        throw new Error('Failed to add project');
    }
};
