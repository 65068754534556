import axios from 'axios';
import { Skill } from '../../../api/data_types';

const BASE_URL = process.env.REACT_APP_SKILLS_BASE_URL;

if (!BASE_URL) {
    throw new Error('REACT_APP_SKILLS_BASE_URL is not defined in the environment variables');
}

export const fetchSkills = async (): Promise<{ skills: Skill[], skillsPerRow: number }> => {
    try {
        const response = await axios.get<{ skills: Skill[], skillsPerRow: number }>(BASE_URL);
        console.log("Skills fetched from server:", response.data);
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch skills:', error.message);
        throw new Error('Failed to fetch skills');
    }
};

export const addSkill = async (newSkill: Skill): Promise<Skill> => {
    try {
        const response = await axios.post<Skill>(BASE_URL, newSkill);
        return response.data;
    } catch (error) {
        throw new Error('Failed to add skill');
    }
};
