import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import './SkillDashboard.css';

const SkillDashboard = () => {
    const [newSkillTitle, setNewSkillTitle] = useState('');
    const [newSkillImage, setNewSkillImage] = useState<File | null>(null);
    const [deleteSkillId, setDeleteSkillId] = useState('');
    const [skillsPerRow, setSkillsPerRow] = useState('');

    const skillsBaseUrl = process.env.REACT_APP_SKILLS_BASE_URL; // Base URL for the API server

    const handleAddSkillSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!newSkillTitle || !newSkillImage) {
            alert('Please provide both title and image for the skill.');
            return;
        }

        const formData = new FormData();
        formData.append('title', newSkillTitle);
        formData.append('image', newSkillImage);

        try {
            const response = await fetch(`${skillsBaseUrl}`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            if (response.ok) {
                alert('Skill added successfully');
                setNewSkillTitle('');
                setNewSkillImage(null);
            } else {
                alert('Failed to add skill');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error adding skill');
        }
    };

    const handleDeleteSkillSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!deleteSkillId) {
            alert('Please provide the skill ID to delete.');
            return;
        }

        try {
            const response = await fetch(`${skillsBaseUrl}/${deleteSkillId}`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (response.ok) {
                alert('Skill deleted successfully');
                setDeleteSkillId('');
            } else {
                alert('Failed to delete skill');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error deleting skill');
        }
    };

    const handleUpdateSkillsPerRow = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!skillsPerRow || isNaN(Number(skillsPerRow))) {
            alert('Please provide a valid number for skills per row.');
            return;
        }

        try {
            const response = await fetch(`${skillsBaseUrl}/updateSkillsPerRow`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ skillsPerRow }),
                credentials: 'include',
            });

            if (response.ok) {
                alert('skillsPerRow updated successfully');
                setSkillsPerRow('');
            } else {
                alert('Failed to update skillsPerRow');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error updating skillsPerRow');
        }
    };

    return (
        <Box className="skill-dashboard">
            <form onSubmit={handleAddSkillSubmit}>
                <h2>Add a New Skill</h2>
                <TextField
                    label="Skill Title"
                    value={newSkillTitle}
                    onChange={(e) => setNewSkillTitle(e.target.value)}
                    fullWidth
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setNewSkillImage(e.target.files?.[0] || null)}
                    required
                />
                <Button type="submit" variant="contained" color="primary">
                    Add Skill
                </Button>
            </form>

            <form onSubmit={handleDeleteSkillSubmit}>
                <h2>Delete a Skill</h2>
                <TextField
                    label="Skill ID"
                    value={deleteSkillId}
                    onChange={(e) => setDeleteSkillId(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="secondary">
                    Delete Skill
                </Button>
            </form>

            <form onSubmit={handleUpdateSkillsPerRow}>
                <h2>Update Skills Per Row</h2>
                <TextField
                    label="Skills Per Row"
                    value={skillsPerRow}
                    onChange={(e) => setSkillsPerRow(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="primary">
                    Update Skills Per Row
                </Button>
            </form>
        </Box>
    );
};

export default SkillDashboard;
