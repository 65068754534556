import IntroSlide from "../../Slides/intro_slide/Intro_slide";
import MyExpertise from "../../Slides/my_expertise/my_expertise";
import MyExperience from "../../Slides/my_experience/my_experience";
import MyProjects from "../../Slides/my_projects/my_projects";
import { Sparkles } from "../../components/Sparkles/Sparkles";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import './Home.css';
import { Box } from "@mui/system";
import Socials_slide from "../../Slides/socials/socials";

const Home = () => {
    const navigate = useNavigate();

    const handleLoginButtonClick = () => {
        navigate('/login');
    }

    return (
        <div className="app">
            <Box className='transition_div' sx={{ background: 'linear-gradient(0deg, var(--color-palette-1) 0%, var(--base-color) 100%)' }}>
                <Sparkles size={2} color='var(--transition-sparkle-color-1)' />
                <Button className="login_button" onClick={handleLoginButtonClick}/>
            </Box>
            <IntroSlide />
            <Box className='transition_div' sx={{ background: 'linear-gradient(0deg, var(--base-color) 0%, var(--color-palette-1) 100%)' }}>
                <Sparkles size={2} color='var(--transition-sparkle-color-1)' />
            </Box>
            <MyExpertise />
            <Box className='transition_div' sx={{ background: 'linear-gradient(0deg, var(--color-palette-1) 0%, var(--base-color) 100%)' }}>
                <Sparkles size={2} color='var(--transition-sparkle-color-1)' />
            </Box>
            <MyProjects />
            <Box className='transition_div' sx={{ background: 'linear-gradient(0deg, var(--base-color) 0%, var(--color-palette-1) 100%)' }}>
                <Sparkles size={2} color='var(--transition-sparkle-color-1)' />
            </Box>
            <MyExperience />
            <Box className='transition_div' sx={{ background: 'linear-gradient(0deg, var(--color-palette-1) 0%, var(--base-color) 100%)' }}>
                <Sparkles size={2} color='var(--transition-sparkle-color-1)' />
            </Box>
            <Socials_slide/>
        </div>
    )
};

export default Home;
