import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
    const navigate = useNavigate();

    const handleButtonClick = (path: string) => {
        navigate(path);
    };

    return (
        <Box className="dashboard">
            <Button onClick={() => handleButtonClick('/dashboard/themes')}>Themes</Button>
            <Button onClick={() => handleButtonClick('/dashboard/skills')}>Skills</Button>
            <Button onClick={() => handleButtonClick('/dashboard/projects')}>Projects</Button>
            <Button onClick={() => handleButtonClick('/dashboard/experiences')}>Experiences</Button>
        </Box>
    );
};

export default Dashboard;