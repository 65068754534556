import { useRef, useState, useEffect } from "react";
import "./GlowDiv.css"; // Ensure this CSS file includes the necessary styles
import React from "react";

interface FeatureDivItemProps {
  title: String;
  description: String;
}

interface GlowDivProps {
  children: React.ReactNode;
  glowSizeMultiplier: number;
}

export const FeatureDivItem: React.FC<FeatureDivItemProps> = ({
  title,
  description,
}) => {
  return (
    <div className="feature">
      <div className="feature-title">{title}</div>
      <div className="feature-description">{description}</div>
    </div>
  );
};

const GlowDiv: React.FC<GlowDivProps> = ({ children, glowSizeMultiplier }) => {
  const featuresRef = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [glowSize, setGlowSize] = useState({ width: 100, height: 100 }); // Default size

  useEffect(() => {
    const updateGlowSize = () => {
      if (featuresRef.current) {
        const width = featuresRef.current.offsetWidth;
        const height = featuresRef.current.offsetHeight;
        const size = Math.min(width, height) * glowSizeMultiplier; // Use the current multiplier
        console.log("glowSizeMultiplier (GlowDiv): ", glowSizeMultiplier);
        setGlowSize({ width: size, height: size });
      }
    };

    // Set up a ResizeObserver to monitor changes in size of the featuresRef element
    const resizeObserver = new ResizeObserver(updateGlowSize);
    const currentRef = featuresRef.current;
    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    updateGlowSize(); // Call initially and on multiplier change

    // Clean-up function
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [glowSizeMultiplier]); // Include glowSizeMultiplier in dependencies

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (featuresRef.current) {
        const rect = featuresRef.current.getBoundingClientRect();
        setMousePosition({
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        });
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div ref={featuresRef} className="features">
      <div
        className="glow-effect"
        style={{
          position: "absolute",
          left: `${mousePosition.x - glowSize.width / 2}px`,
          top: `${mousePosition.y - glowSize.height / 2}px`,
          width: `${glowSize.width}px`,
          height: `${glowSize.height}px`,
          borderRadius: "50%",
          pointerEvents: "none",
          zIndex: 1,
        }}
      />
      <div className="children-wrapper" style={{ width: '100%' }}>
        {children}
      </div>
    </div>
  );
};

export default GlowDiv;