import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import './ProjectDashboard.css';

const ProjectDashboard = () => {
    const [newProjectTitle, setNewProjectTitle] = useState('');
    const [newProjectDescription, setNewProjectDescription] = useState('');
    const [newProjectGitHubLink, setNewProjectGitHubLink] = useState('');
    const [newProjectImage, setNewProjectImage] = useState<File | null>(null);
    const [deleteProjectId, setDeleteProjectId] = useState('');
    const [projectsPerRow, setProjectsPerRow] = useState('');
    const [glowSizeMultiplier, setGlowSizeMultiplier] = useState('');

    const projectsBaseUrl = process.env.REACT_APP_PROJECTS_BASE_URL; // Base URL for the API server

    const handleAddProjectSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!newProjectTitle || !newProjectDescription || !newProjectGitHubLink || !newProjectImage) {
            alert('Please provide all required fields for the project.');
            return;
        }

        const formData = new FormData();
        formData.append('projectTitle', newProjectTitle);
        formData.append('projectDescription', newProjectDescription);
        formData.append('projectGitHubLink', newProjectGitHubLink);
        formData.append('image', newProjectImage);

        try {
            const response = await fetch(`${projectsBaseUrl}`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            if (response.ok) {
                alert('Project added successfully');
                setNewProjectTitle('');
                setNewProjectDescription('');
                setNewProjectGitHubLink('');
                setNewProjectImage(null);
            } else {
                alert('Failed to add project');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error adding project');
        }
    };

    const handleDeleteProjectSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!deleteProjectId) {
            alert('Please provide the project ID to delete.');
            return;
        }

        try {
            const response = await fetch(`${projectsBaseUrl}/${deleteProjectId}`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (response.ok) {
                alert('Project deleted successfully');
                setDeleteProjectId('');
            } else {
                alert('Failed to delete project');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error deleting project');
        }
    };

    const handleUpdateProjectsPerRow = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!projectsPerRow || isNaN(Number(projectsPerRow))) {
            alert('Please provide a valid number for projects per row.');
            return;
        }

        try {
            const response = await fetch(`${projectsBaseUrl}/updateProjectsPerRow`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectsPerRow }),
                credentials: 'include',
            });

            if (response.ok) {
                alert('projectsPerRow updated successfully');
                setProjectsPerRow('');
            } else {
                alert('Failed to update projectsPerRow');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error updating projectsPerRow');
        }
    };

    const handleUpdateGlowSizeMultiplier = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!glowSizeMultiplier || isNaN(Number(glowSizeMultiplier))) {
            alert('Please provide a valid number for glow size multiplier.');
            return;
        }

        try {
            const response = await fetch(`${projectsBaseUrl}/updateGlowSizeMultiplier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ glowSizeMultiplier }),
                credentials: 'include',
            });

            if (response.ok) {
                alert('glowSizeMultiplier updated successfully');
                setGlowSizeMultiplier('');
            } else {
                alert('Failed to update glowSizeMultiplier');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error updating glowSizeMultiplier');
        }
    };

    return (
        <Box className="project-dashboard">
            <form onSubmit={handleAddProjectSubmit}>
                <h2>Add a New Project</h2>
                <TextField
                    label="Project Title"
                    value={newProjectTitle}
                    onChange={(e) => setNewProjectTitle(e.target.value)}
                    fullWidth
                    required
                />
                <TextField
                    label="Project Description"
                    value={newProjectDescription}
                    onChange={(e) => setNewProjectDescription(e.target.value)}
                    fullWidth
                    required
                />
                <TextField
                    label="Project GitHub Link"
                    value={newProjectGitHubLink}
                    onChange={(e) => setNewProjectGitHubLink(e.target.value)}
                    fullWidth
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setNewProjectImage(e.target.files?.[0] || null)}
                    required
                />
                <Button type="submit" variant="contained" color="primary">
                    Add Project
                </Button>
            </form>

            <form onSubmit={handleDeleteProjectSubmit}>
                <h2>Delete a Project</h2>
                <TextField
                    label="Project ID"
                    value={deleteProjectId}
                    onChange={(e) => setDeleteProjectId(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="secondary">
                    Delete Project
                </Button>
            </form>

            <form onSubmit={handleUpdateProjectsPerRow}>
                <h2>Update Projects Per Row</h2>
                <TextField
                    label="Projects Per Row"
                    value={projectsPerRow}
                    onChange={(e) => setProjectsPerRow(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="primary">
                    Update Projects Per Row
                </Button>
            </form>

            <form onSubmit={handleUpdateGlowSizeMultiplier}>
                <h2>Update Glow Size Multiplier</h2>
                <TextField
                    label="Glow Size Multiplier"
                    value={glowSizeMultiplier}
                    onChange={(e) => setGlowSizeMultiplier(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="primary">
                    Update Glow Size Multiplier
                </Button>
            </form>
        </Box>
    );
};

export default ProjectDashboard;