import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import SchoolIcon from '@mui/icons-material/School';
// Import other icons as needed

export const getIcon = (iconType: string) => {
    switch (iconType) {
        case 'work':
            return <LaptopMacIcon sx={{ scale: '1.4' }} />;
        case 'education':
            return <SchoolIcon sx={{ scale: '1.4' }} />;
        default:
            return <LaptopMacIcon sx={{ scale: '1.4' }} />;
    }
};
