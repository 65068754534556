import Tilt from 'react-parallax-tilt';
import './SkillCard.css';
import '../../Slides/Theme.css'
import React from 'react';

interface CardProps {
  id: string,
  titleString: string,
  imageUrl: string,
  skillsPerRow: number
}

const SkillCard: React.FC<CardProps> = ({ id, titleString, imageUrl, skillsPerRow}) => {

  return (
    <Tilt
      className='tilt-div skill-card'
      glareEnable={true}
      glareMaxOpacity={0.45}
      glareColor="var(--skill-card-glare-color)"
      glarePosition="all"
      // glareBorderRadius='50px'
      style={{ width: '100%', flex: `1 0 calc(100% / ${skillsPerRow} - 18px)`, maxWidth: `calc(100% / ${skillsPerRow} - 18px)`}}
    >
      <div className="tilt-container">
        <div className='card-title-container' id={`${id}-container`}>
          <div className='image-container'>
            <img src={imageUrl} alt="Card Visual" style={{ width: '100%', height: 'auto' }} />
          </div>
          <div className="card-title" id={`${id}-title`}>{titleString}</div>
        </div>
      </div>
    </Tilt>
  )
}

export default SkillCard;