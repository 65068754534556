import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Pages/Home/Home";
import LoginPage from "./Pages/LoginPage/LoginPage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import SkillDashboard from "./Pages/Dashboard/Skills/SkillDashboard";
import ProjectDashboard from "./Pages/Dashboard/Projects/ProjectDashboard";
import ExperienceDashboard from "./Pages/Dashboard/Experiences/ExperienceDashboard";
import ThemeDashboard from "./Pages/Dashboard/Themes/ThemeDashboard";
import axios from "axios";
import { useEffect } from "react";

const App = () => {

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_VISITOR_INFO}`)
            .then(response => {
                if (response.data.status === 'success') {
                    // Now you have visitor's info
                    axios.post(`${process.env.REACT_APP_NOTIFICATION_BASE_URL}`, {
                        status: response.data.status,
                        continent: response.data.continent,
                        continentCode: response.data.continentCode,
                        country: response.data.country,
                        countryCode: response.data.countryCode,
                        region: response.data.region,
                        regionName: response.data.regionName,
                        city: response.data.city,
                        zip: response.data.zip,
                        lat: response.data.lat,
                        lon: response.data.lon,
                        timezone: response.data.timezone,
                        isp: response.data.isp,
                        org: response.data.org,
                        as: response.data.as,
                        query: response.data.query
                    })                    
                    .then(() => {
                        console.log('Visit notification sent successfully.');
                    })
                    .catch(error => {
                        console.error('Error sending visit notification:', error);
                    });
                } else {
                    console.log('Failed to fetch visitor data:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching visitor data:', error);
            });
    }, []);    
    
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/themes" element={<ThemeDashboard />} />
                <Route path="/dashboard/skills" element={<SkillDashboard />} />
                <Route path="/dashboard/projects" element={<ProjectDashboard />} />
                <Route path="/dashboard/experiences" element={<ExperienceDashboard />} />
            </Routes>
        </Router>
    )
}

export default App;