import './my_experience.css';
import "../Theme.css";
import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { fetchTimelineElements } from '../../backend/timeline_elements';
import { TimelineItem } from '../../../../api/data_types';
import { getIcon } from './icons';

const My_experience = () => {
    const [timelineData, setTimelineData] = useState<TimelineItem[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const loadTimelineElements = async () => {
            try {
                const response = await fetchTimelineElements();
                setTimelineData(response);
            } catch (error: any) {
                setError('Failed to fetch timeline data');
                console.error(error.message);
            }
        };

        loadTimelineElements();
    }, []);

    return (
        <Box className='slide' id='my_experience_slide'>
            <Grid className='slide_container' id='my_experience_slide_container'>
                <Grid item className='slide_content' id='slide-title'>
                    My Experience
                </Grid>
                <VerticalTimeline 
                className='component_container'
                lineColor='var(--timeline-line-color)'
                >
                    {timelineData.length ? timelineData.map((item) => (
                        <VerticalTimelineElement
                            key={item.id}
                            className={`vertical-timeline-element--${item.type} timeline-element`}
                            date={item.date}
                            iconStyle={{ 
                                background: "var(--timeline-element-icon-background-color)", 
                                color: "var(--timeline-element-icon-color)",
                                boxShadow: "0 0 0 4px var(--timeline-element-icon-box-shadow),inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)",
                            }}
                            icon={getIcon(item.type)}
                            contentStyle={{
                                background: "var(--timeline-element-content-background-color)",
                                color: "var(--timeline-element-content-font-color)",
                                boxShadow: "0 3px 0 var(--timeline-element-content-box-shadow)"
                            }}
                            contentArrowStyle={{ borderRight: `7px solid var(--timeline-element-content-arrow-color)` }}
                        >
                            <h3 className="vertical-timeline-element-title">{item.title}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{item.subtitle}</h4>
                            <p>{item.description}</p>
                            
                            <span className="timeline-element-id">{item.id}</span>
                        </VerticalTimelineElement>
                    )) : (
                        <p>{error || 'No timeline data available'}</p>
                    )}
                </VerticalTimeline>
            </Grid>
        </Box>
    );
};

export default My_experience;
