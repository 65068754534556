import './my_expertise.css';
import "../Theme.css";

import React, { useEffect, useState } from 'react';
import { Box, Grid, List, Typography } from '@mui/material';
import SkillCard from '../../components/SkillCard/SkillCard';
import { Skill } from '../../../../api/data_types';
import { fetchSkills } from '../../backend/skills';

const My_expertise = () => {
    const [skillsPerRow, setSkillsPerRow] = useState<number>(4);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadSkills = async () => {
            try {
                const data = await fetchSkills();
                if (data.skills && data.skillsPerRow) {
                    setSkills(data.skills);
                    setSkillsPerRow(data.skillsPerRow); // Update state based on fetched data
                } else {
                    setError('No skills data available');
                }
            } catch (error) {
                setError('Failed to fetch skills');
                console.error(error);
            }
        };
        loadSkills();
    }, []);

    const groupSkills = (skills: Skill[]): Skill[][] => {
        const groupedSkills: Skill[][] = [];
        for (let i = 0; i < skills.length; i += skillsPerRow) {
            // Use slice to get up to the next four items and push that as a new group
            groupedSkills.push(skills.slice(i, i + skillsPerRow));
        }
        return groupedSkills;
    }

    return (
        <Box className='slide' id='my_expertise_slide'>
            <Grid className='slide_container' id='my_expertise_slide_container' justifyContent={'center'}>
                <Grid item className='slide_content' id='slide-title'>
                    My Expertise
                </Grid>
                {error ? (
                    <Typography variant="h6" color="error" textAlign="center">
                        {error} {/* Displaying the error message */}
                    </Typography>
                ) : (
                    groupSkills(skills).map((group, index) => (
                        <List key={index} className='component_container' disablePadding={true}>
                            {group.map(skill => (
                                <SkillCard key={skill.id} id={skill.id} titleString={skill.title} imageUrl={skill.imageUrl} skillsPerRow={skillsPerRow} />
                            ))}
                        </List>
                    ))
                )}
            </Grid>
        </Box>
    );
};

export default My_expertise;
